/* OpenSans */
@font-face {
	font-family: 'Open Sans';
	src: local("Open Sans Light"), local("OpenSans-Light"),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Light.woff2') format('woff2'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Light.woff') format('woff'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Light.ttf')format("truetype");
	font-weight: 300;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local("Open Sans Regular"), local("OpenSans-Regular"),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Regular.woff2') format('woff2'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Regular.woff') format('woff'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Regular.ttf')format("truetype");
	font-weight: 400;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local("Open Sans SemiBold"), local("OpenSans-SemiBold"),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-SemiBold.woff2') format('woff2'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-SemiBold.woff') format('woff'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-SemiBold.ttf')format("truetype");
	font-weight: 600;
	font-style: normal;
  font-display: swap;
}

@font-face {
	font-family: 'Open Sans';
	src: local("Open Sans Bold"), local("OpenSans-Bold"),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Bold.woff2') format('woff2'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Bold.woff') format('woff'),
    url('https://www.alufcar.cz/fonts/OpenSans/OpenSans-Bold.ttf')format("truetype");
	font-weight: 700;
	font-style: normal;
  font-display: swap;
}

/* OpenSans */

/* Helvetica Now Display */
@font-face {
  font-family: "Helvetica Now Display";
  src: url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.eot");
  src: local("Helvetica Now Display Light"), local("HelveticaNowDisplay-Light"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.eot?#iefix") format("embedded-opentype"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.woff2") format("woff2"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.woff") format("woff"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Light.ttf") format("truetype");
  font-weight: 300;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.eot");
  src: local("Helvetica Now Display Regular"), local("HelveticaNowDisplay-Regular"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.eot?#iefix") format("embedded-opentype"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff2") format("woff2"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.woff") format("woff"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Regular.ttf") format("truetype");
  font-weight: 400;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.eot");
  src: local("Helvetica Now Display Medium"), local("HelveticaNowDisplay-Medium"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.eot?#iefix") format("embedded-opentype"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.woff2") format("woff2"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.woff") format("woff"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Medium.ttf") format("truetype");
  font-weight: 500;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.eot");
  src: local("Helvetica Now Display Bold"), local("HelveticaNowDisplay-Bold"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.eot?#iefix") format("embedded-opentype"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff2") format("woff2"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.woff") format("woff"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.ttf") format("truetype");
  font-weight: 700;
  font-style: normal;
  font-display: swap;
}

@font-face {
  font-family: "Helvetica Now Display";
  src: url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-Bold.eot");
  src: local("Helvetica Now Display ExtBd"), local("HelveticaNowDisplay-ExtraBold"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-ExtraBold.eot?#iefix")
      format("embedded-opentype"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-ExtraBold.woff2") format("woff2"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-ExtraBold.woff") format("woff"),
    url("https://www.alufcar.cz/fonts/HelveticaNowDisplay/HelveticaNowDisplay-ExtraBold.ttf") format("truetype");
  font-weight: 800;
  font-style: normal;
  font-display: swap;
}
/* Helvetica Now Display */