/* @import "/src/fonts/fonts.css"; */
@import "../node_modules/modern-normalize/modern-normalize.css";
@import "./styles/loader.css";

:root {
  /* colors */
  --color--white: #fff;
  --color--dark: #0e0e12;
  --color--accent: #9cfe01;
  --color--grey: #8d8d8d;

  /* background-color: var(--color--white, #fff); */
  /* background-color: var(--color--dark, #0e0e12); */
  /* background-color: var( --color--accent, #9CFE01); */
  /* background-color: var( --color--grey, #8d8d8d); */

  /* other */
  --items: 1;
  --gap: 8px;
  --transition--20: 20ms linear;
  --transition--100: 100ms linear;
  --transition--200: 200ms linear;
  --transition--300: 300ms linear;
  --transition--400: 400ms linear;
  --border-radius--primary: 26px;
  --border-radius--4: 4px;
  --box-shadow--main: 0 0.5px 1px rgba(0, 0, 0, 0.1),
    0 2px 6px rgba(90, 100, 109, 0.2);
  --box-shadow--active: 0 0 8px -2px rgba(0, 0, 0, 0.1),
    0 6px 20px -3px rgba(0, 0, 0, 0.2);
}

body {
  font-family: "Barlow", sans-serif;
  color: var(--color--white, #fff);
  font-size: 16px;
  line-height: normal;

  background-color: var(--color--dark, #0e0e12);
}
body.isLoading,
body.modalIsOpen,
body.mobileMenuIsOpen {
  overflow: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0;
  padding: 0;
}

ul,
ol {
  margin: 0;
  padding: 0;
  list-style: none;
}

img {
  display: block;
  width: 100%;
  height: auto;
}

svg {
  pointer-events: none;
}

a {
  text-decoration: none;
}

input,
textarea {
  background-color: transparent;
  outline: none;
}

a,
button {
  cursor: pointer;
  padding: 0;
}

.visually-hidden {
  @extend %absolute;
  white-space: nowrap;
  width: 1px;
  height: 1px;
  overflow: hidden;
  border: 0;
  padding: 0;
  clip: rect(0 0 0 0);
  clip-path: inset(50%);
  margin: -1px;
}

.grow-1 {
  flex-grow: 1;
}
.flex-100 {
  flex: 0 0 100%;
}
.br-0 {
  border-radius: 0;
}
.fd-row {
  flex-direction: row;
}
.fd-column {
  flex-direction: column;
}

.min-w-60 {
  min-width: 60px;
}
.min-w-80 {
  min-width: 80px;
}
.min-w-100 {
  min-width: 100px;
}
.min-w-120 {
  min-width: 120px;
}
.min-w-160 {
  min-width: 160px;
}
.min-w-200 {
  min-width: 200px;
}
.min-h-40 {
  min-height: 40px;
}

.cardset__list {
  display: flex;
  flex-wrap: wrap;
  gap: var(--gap);
}
.cardset__item {
  flex-basis: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
  width: calc((100% - var(--gap) * (var(--items) - 1)) / var(--items));
}

.items-1 {
  --items: 1;
}
.items-2 {
  --items: 2;
}
.items-3 {
  --items: 3;
}

/* .items-1-1-1 { --items: 1; } */
.items-2-2-2 {
  --items: 2;
}
.items-2-2-3 {
  --items: 2;
}
.items-3-3-3 {
  --items: 3;
}

@media screen and (min-width: 375px) {
  .min-375-items-2 {
    --items: 2;
  }
}
@media screen and (min-width: 425px) {
  .min-425-items-2 {
    --items: 2;
  }
}
@media screen and (min-width: 480px) {
  .min-480-items-2 {
    --items: 2;
  }
  .min-480-items-3 {
    --items: 3;
  }
}
@media screen and (min-width: 600px) {
  .min-600-items-2 {
    --items: 2;
  }
  .min-600-items-3 {
    --items: 3;
  }
}

@media screen and (min-width: 768px) {
  .min-768-items-1 {
    --items: 1;
  }
  .min-768-items-2 {
    --items: 2;
  }
  .min-768-items-3 {
    --items: 3;
  }
  .min-768-items-4 {
    --items: 4;
  }

  .items-1-2-2 {
    --items: 2;
  }
  .items-1-2-3 {
    --items: 2;
  }
  .items-1-3-3 {
    --items: 3;
  }
}
@media screen and (min-width: 1024px) {
  .min-1024-items-2 {
    --items: 2;
  }
  .min-1024-items-3 {
    --items: 3;
  }
  .min-1024-items-4 {
    --items: 4;
  }

  .min-1024-items-3_2 {
    --items: calc(3 / 2);
  }
}

@media screen and (min-width: 1440px) {
  .min-1440-items-1 {
    --items: 1;
  }
  .min-1440-items-2 {
    --items: 2;
  }
  .min-1440-items-3 {
    --items: 3;
  }
  .min-1440-items-4 {
    --items: 4;
  }

  .min-1440-items-3_2 {
    --items: calc(3 / 2);
  }
  .min-1440-items-4_3 {
    --items: calc(4 / 3);
  }

  .items-1-1-3 {
    --items: 3;
  }
  .items-1-2-3 {
    --items: 3;
  }
  .items-2-2-3 {
    --items: 3;
  }

  .min-1440-fd-row {
    flex-direction: row;
  }
}

.basic {
  color: inherit;
  background-color: transparent;
}

.accent {
  color: #fff;
  background-color: var(--color--accent, #f19e1d);
}
.blue {
  color: var(--color--blue, #0653a5);
  background-color: var(--color--blue-light, #c4ecf8);
}
.blueviolet {
  color: var(--color--blueviolet, #794c8a);
  background-color: var(--color--blueviolet-light, #e2c4ec);
}
.orange {
  color: var(--color--orange, #f19e1d);
  background-color: var(--color--orange-light, #ffe1a8);
}
.green {
  color: var(--color--green, #06a561);
  background-color: var(--color--green-light, #c4f8e2);
}
.green--dark {
  color: var(--color--green-light, #c4f8e2);
  background-color: var(--color--green, #06a561);
}
.gray {
  color: var(--color--gray, #848484);
  background-color: var(--color--gray-light, #d9d9d9);
}
.red {
  color: var(--color--red, #a51806);
  background-color: var(--color--red-light, #f8cac4);
}

.telegram {
  background-color: #08c;
}
.viber {
  background-color: #59267c;
}
.whatsapp {
  background-color: #25d366;
}

.test {
  position: relative;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
  height: 40px;

  background-color: transparent;
  border: 0;
  color: inherit;

  border-radius: 50%;
  fill: currentColor;
  border: none;
  cursor: pointer;

  transform: scale(1.05);

  width: fit-content;
  width: 100%;
  height: 100%;
  min-height: max-content;
  display: inline-block;

  flex-shrink: 0;
  justify-content: space-between;

  margin-bottom: auto;
}
